import React from "react";
import SignUpButton from "../../Button/SignUpButton";
import OakAdventureStyles from "./OakAdventure.styles";

const { Container, Title, SubTitle } = OakAdventureStyles;

const OakAdventure = () => {
    return (
        <Container>
            <Title>START AN OAK ADVENTURE</Title>
            <SubTitle>
                Are you ambitious and willing to learn real programming?
            </SubTitle>
            <SignUpButton />
        </Container>
    );
};

export default OakAdventure;
