import React from "react";
import SocialMediaStyles from "./SocialMedia.styles";
import { StaticImage } from "gatsby-plugin-image";

const { SocialMediaContainer, SocialMediaBoxes, SocialMediaBox } =
    SocialMediaStyles;

const SocialMedia = () => {
    return (
        <SocialMediaContainer>
            <SocialMediaBoxes>
                <SocialMediaBox>
                    <a href="https://www.instagram.com/oakforge.it/">
                        <StaticImage
                            src="../../images/SocialMedia/instagram.png"
                            alt="instagram logo"
                            placeholder="blurred"
                        />
                    </a>
                </SocialMediaBox>
                <SocialMediaBox>
                    <a href="https://www.facebook.com/Oakforge-107709825319613">
                        <StaticImage
                            src="../../images/SocialMedia/facebook.png"
                            alt="instagram logo"
                            placeholder="blurred"
                        />
                    </a>
                </SocialMediaBox>
                <SocialMediaBox style={{ borderBottom: "0px" }}>
                    <a href="https://www.linkedin.com/company/oakforge/">
                        <StaticImage
                            src="../../images/SocialMedia/linkedin.png"
                            alt="instagram logo"
                            placeholder="blurred"
                        />
                    </a>
                </SocialMediaBox>
            </SocialMediaBoxes>
        </SocialMediaContainer>
    );
};

export default SocialMedia;
