import React from "react";
import WhatWillYouDoMobileStyles from "./WhatWillYouDoMobile.styles";
import { StaticImage } from "gatsby-plugin-image";

const {
    Container,
    ContentContainer,
    TitleContainer,
    TitleText,
    BoxesContainer,
    LeftRowContainer,
    LeftBox,
    RightRowContainer,
    RightBox,
    CornerCutBox,
    CornerCutBoxInside,
    BoxText,
} = WhatWillYouDoMobileStyles;

const WhatWillYouDoMobile = () => {
    return (
        <Container>
            <ContentContainer>
                <TitleContainer>
                    <TitleText>What Will You Do</TitleText>
                </TitleContainer>
                <BoxesContainer>
                    <LeftRowContainer>
                        <LeftBox first />
                        <LeftBox />
                        <LeftBox />
                        <LeftBox />
                        <LeftBox />
                    </LeftRowContainer>
                    <RightRowContainer>
                        <RightBox>
                            <CornerCutBox>
                                <CornerCutBoxInside>
                                    <StaticImage
                                        src={
                                            "../../../../images/WhatWillYouDo/book.png"
                                        }
                                        alt="Book img"
                                        placeholder="blurred"
                                    />
                                    <BoxText>
                                        {"<Get Free Programming Knowledge>"}
                                    </BoxText>
                                </CornerCutBoxInside>
                            </CornerCutBox>
                        </RightBox>
                        <RightBox>
                            <CornerCutBox>
                                <CornerCutBoxInside>
                                    <StaticImage
                                        src={
                                            "../../../../images/WhatWillYouDo/portfolio.png"
                                        }
                                        alt="Book img"
                                        placeholder="blurred"
                                    />
                                    <BoxText>
                                        {"<Gain Real Experience>"}
                                    </BoxText>
                                </CornerCutBoxInside>
                            </CornerCutBox>
                        </RightBox>
                        <RightBox>
                            <CornerCutBox>
                                <CornerCutBoxInside>
                                    <StaticImage
                                        src={
                                            "../../../../images/WhatWillYouDo/chat.png"
                                        }
                                        alt="Book img"
                                        placeholder="blurred"
                                    />
                                    <BoxText>{"<Improve Soft Skills>"}</BoxText>
                                </CornerCutBoxInside>
                            </CornerCutBox>
                        </RightBox>
                        <RightBox>
                            <CornerCutBox>
                                <CornerCutBoxInside>
                                    <StaticImage
                                        src={
                                            "../../../../images/WhatWillYouDo/people.png"
                                        }
                                        alt="Book img"
                                        placeholder="blurred"
                                    />
                                    <BoxText>
                                        {"<Become A Better Team Player>"}
                                    </BoxText>
                                </CornerCutBoxInside>
                            </CornerCutBox>
                        </RightBox>
                        <RightBox>
                            <CornerCutBox>
                                <CornerCutBoxInside>
                                    <StaticImage
                                        src={
                                            "../../../../images/WhatWillYouDo/web-dev.png"
                                        }
                                        alt="Book img"
                                        placeholder="blurred"
                                    />
                                    <BoxText>
                                        {
                                            "<Will Understand The It Language Better>"
                                        }
                                    </BoxText>
                                </CornerCutBoxInside>
                            </CornerCutBox>
                        </RightBox>
                    </RightRowContainer>
                </BoxesContainer>
            </ContentContainer>
        </Container>
    );
};

export default WhatWillYouDoMobile;
