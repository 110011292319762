import React from "react";
import Styles from "./ThreePoints.styles";
import { StaticImage } from "gatsby-plugin-image";

const {
    Container,
    PictureTextRow,
    PictureBox,
    TextBoxOutline,
    TextBox,
    TextTitle,
    TextSubtitle,
} = Styles;

const ThreePoints = () => {
    return (
        <Container id="about">
            <PictureTextRow>
                <PictureBox>
                    <StaticImage
                        src={"../../../images/ThreePoints/point1.png"}
                        alt="Point 1 Picture"
                        placeholder="blurred"
                    />
                </PictureBox>
                <TextBoxOutline>
                    <TextBox>
                        <StaticImage
                            src={"../../../images/ThreePoints/one.png"}
                            alt="Book img"
                            placeholder="blurred"
                        />
                        <TextTitle>
                            {"<How long does the course take?>"}
                        </TextTitle>
                        <TextSubtitle>
                            We estimate that it takes about six months fot the
                            programmer to be able to independently work in IT,
                            although some of the students are ready for it in
                            just a few weeks.
                        </TextSubtitle>
                    </TextBox>
                </TextBoxOutline>
            </PictureTextRow>
            <PictureTextRow reverse>
                <TextBoxOutline>
                    <TextBox>
                        <StaticImage
                            src={"../../../images/ThreePoints/two.png"}
                            alt="Book img"
                            placeholder="blurred"
                        />
                        <TextTitle>
                            {"<How does the recruitment process look?>"}
                        </TextTitle>
                        <TextSubtitle>
                            We choose people who are motivated, passionate and
                            positive, ... who have basic theoritical knowledge
                            and are able to write code on their own.
                        </TextSubtitle>
                    </TextBox>
                </TextBoxOutline>
                <PictureBox>
                    <StaticImage
                        src={"../../../images/ThreePoints/point2.png"}
                        alt="Point 2 Picture"
                        placeholder="blurred"
                    />
                </PictureBox>
            </PictureTextRow>
            <PictureTextRow>
                <PictureBox>
                    <StaticImage
                        src={"../../../images/ThreePoints/point3.png"}
                        alt="Point 3 Picture"
                        placeholder="blurred"
                    />
                </PictureBox>
                <TextBoxOutline>
                    <TextBox>
                        <StaticImage
                            src={"../../../images/ThreePoints/three.png"}
                            alt="Book img"
                            placeholder="blurred"
                        />
                        <TextTitle style={{ textAlign: "center" }}>
                            {"<How Big Is The Team?>"}
                        </TextTitle>
                        <TextSubtitle>
                            We work in the teams not bigger than 6 people,
                            chaired by our code architect and product owner. Our
                            experience shows that it's the most accurate number
                            for keeping good working speed and proper
                            communiaction.
                        </TextSubtitle>
                    </TextBox>
                </TextBoxOutline>
            </PictureTextRow>
        </Container>
    );
};

export default ThreePoints;
