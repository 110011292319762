import React from "react";
import SignUpButton from "../../Button/SignUpButton";
import SubTitleMobileSectionStyles from "./SubTitleMobileSection.styles";

const { Container, TextContainer } = SubTitleMobileSectionStyles;

const SubTitleMobileSection = () => {
    return (
        <Container>
            <TextContainer>
                Talents Forge was created to introduce young programmers into
                the IT world and test them against challenges that professional
                face every day. It’s not just an ordinary programming course,
                but a place where future developers work on a real IT projects.
            </TextContainer>

            <SignUpButton />
        </Container>
    );
};

export default SubTitleMobileSection;
