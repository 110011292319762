import styled from "styled-components";

const HomeStyles = {
    Container: styled.div`
        position: relative;
        width: 100%;
        height: 102vh;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        background-color: black;
        @media (max-width: 900px) {
            /* height: 700px; */
            height: 75vh;
            overflow: hidden;
        }
    `,

    TextContainer: styled.div`
        position: absolute;
        display: flex;
        justify-content: flex-start;
        flex-flow: column;
        width: 100%;
        height: 100%;
        @media (max-width: 900px) {
            z-index: 1;
            justify-content: flex-end;
            width: 90%;
            margin-bottom: 60px;
        }
    `,

    TextTitle: styled.div`
        font-family: Barcade;
        font-size: 3rem;
        color: #eefc57;
        text-shadow: 0px 0px 30px #eefc57;
        margin-left: 5%;
        line-height: 80px;
        @media (max-width: 900px) {
            font-size: 1.5rem;
            line-height: 60px;
        }
    `,

    TextSubtitle: styled.div`
        font-size: 1.5rem;
        color: #fff;
        margin-left: 5%;
        margin-top: 2%;
        width: 35%;
        @media (max-width: 900px) {
            display: none;
        }
    `,

    PeopleHome: styled.div`
        position: absolute;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;
        height: 100%;
        @media (max-width: 900px) {
            justify-content: center;
            align-items: center;
        }
    `,

    PeopleHomeImage: styled.div`
        position: relative;
        margin-right: 15%;
        width: 40%;
        @media (max-width: 900px) {
            width: 90%;
            margin: 0;
            filter: brightness(70%);
        }
    `,

    Circle: styled.div`
        position: absolute;
        width: 70%;
        height: 80%;
        filter: blur(150px);
        /* background-color: rgba(238, 252, 0, 0.6); */
        background-color: rgba(238, 252, 87, 0.9);
        border-radius: 50%;
        top: 0%;
        left: 20%;
    `,

    SquaresContainer: styled.div`
        position: absolute;
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        width: 100%;
        height: 100%;
        @media (max-width: 900px) {
            justify-content: center;
            /* align-items: center; */
        }
    `,

    SquaresImage: styled.div`
        width: 70%;
        margin-left: 5%;
        @media (max-width: 900px) {
            width: 100%;
            display: flex;
            justify-content: center;
            overflow: hidden;
            margin: 0;
        }
    `,
};

export default HomeStyles;
