import styled from "styled-components";

const Styles = {
    Container: styled.div`
        width: 100%;
        height: 1300px;
        padding-top: 50px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 900px) {
            height: 100%;
        }
    `,

    PictureTextRow: styled.div`
        width: 90%;
        max-width: 1250px;
        height: 380px;
        display: flex;
        justify-content: space-between;
        @media (max-width: 900px) {
            flex-flow: ${(props) =>
                props.reverse ? "column-reverse" : "column"};
            justify-content: center;
            width: 80%;
            height: 100%;
            gap: 40px;
            margin-bottom: 50px;
        }
    `,

    PictureBox: styled.div`
        /* border: 2px solid black; */
        width: 48.5%;
        @media (max-width: 900px) {
            width: 100%;
        }
    `,

    TextBoxOutline: styled.div`
        background-color: black;
        width: 48.5%;
        display: flex;
        flex-flow: column;
        clip-path: polygon(100% 0%, 100% 80%, 85% 100%, 0 100%, 0 0);
        background: linear-gradient(
            135deg,
            rgba(0, 0, 0, 1) 50%,
            rgba(255, 233, 25, 1) 90%
        );
        @media (max-width: 900px) {
            width: 100%;
            max-width: 600px;
        }
    `,

    TextBox: styled.div`
        background-color: white;
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: space-evenly;
        align-items: center;
        clip-path: polygon(
            99.7% 0.4%,
            99.7% 79.9%,
            84.9% 99.7%,
            0.2% 99.7%,
            0.2% 0.4%
        );
        @media (max-width: 900px) {
            padding: 20px 0px;
            justify-content: space-between;
            gap: 20px;
        }
    `,

    TextTitle: styled.div`
        font-size: 1.5rem;
        font-weight: bold;
        width: 80%;
    `,

    TextSubtitle: styled.div`
        font-size: 1.2rem;
        /* margin: 0px 20px; */
        width: 80%;
    `,
};

export default Styles;
