import styled from "styled-components";

const OakAdventureStyles = {
    Container: styled.div`
        width: 100%;
        height: 350px;
        display: flex;
        flex-flow: column;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 3%;
        margin-bottom: 3%;
        @media (max-width: 900px) {
            width: 100%;
            text-align: center;
            margin-top: 0;
        }
    `,

    Title: styled.div`
        font-size: 2.4rem;
        @media (max-width: 900px) {
            font-size: 2rem;
            width: 90%;
        }
    `,

    SubTitle: styled.div`
        font-size: 1.2rem;
        @media (max-width: 900px) {
            font-size: 1.4rem;
            width: 90%;
        }
    `,
};

export default OakAdventureStyles;
