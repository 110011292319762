import React from "react";
import WhatWillYouDoStyles from "./WhatWillYouDo.styles";
import { StaticImage } from "gatsby-plugin-image";

const {
    Container,
    Title,
    TitleText,
    VerticalLineOne,
    TwoCenterBoxes,
    InsideBoxOne,
    InsideBoxTwo,
    CornerCutBoxesContainer,
    CornerCutBox,
    CornerCutBoxInside,
    BoxText,
} = WhatWillYouDoStyles;

const WhatWillYouDo = () => {
    return (
        <Container>
            <Title>
                <TitleText>WHAT WILL YOU DO</TitleText>
            </Title>

            <VerticalLineOne />

            <TwoCenterBoxes>
                <InsideBoxOne />
                <InsideBoxTwo />
            </TwoCenterBoxes>

            <CornerCutBoxesContainer>
                <CornerCutBox>
                    <CornerCutBoxInside>
                        <StaticImage
                            src={"../../../images/WhatWillYouDo/book.png"}
                            alt="Book img"
                            placeholder="blurred"
                        />
                        <BoxText>{"<Get Free Programming Knowledge>"}</BoxText>
                    </CornerCutBoxInside>
                </CornerCutBox>
                <CornerCutBox>
                    <CornerCutBoxInside>
                        <StaticImage
                            src={"../../../images/WhatWillYouDo/portfolio.png"}
                            alt="Book img"
                            placeholder="blurred"
                        />
                        <BoxText>{"<Gain Real Experience>"}</BoxText>
                    </CornerCutBoxInside>
                </CornerCutBox>
                <CornerCutBox>
                    <CornerCutBoxInside>
                        <StaticImage
                            src={"../../../images/WhatWillYouDo/chat.png"}
                            alt="Book img"
                            placeholder="blurred"
                        />
                        <BoxText>{"<Improve Soft Skills>"}</BoxText>
                    </CornerCutBoxInside>
                </CornerCutBox>
            </CornerCutBoxesContainer>

            <VerticalLineOne />

            <TwoCenterBoxes>
                <InsideBoxOne />
            </TwoCenterBoxes>

            <CornerCutBoxesContainer width={55} maxWidth={800}>
                <CornerCutBox style={{ marginLeft: "15px" }}>
                    <CornerCutBoxInside>
                        <StaticImage
                            src={"../../../images/WhatWillYouDo/people.png"}
                            alt="Book img"
                            placeholder="blurred"
                        />
                        <BoxText>{"<Become A Better Team Player>"}</BoxText>
                    </CornerCutBoxInside>
                </CornerCutBox>
                <CornerCutBox>
                    <CornerCutBoxInside>
                        <StaticImage
                            src={"../../../images/WhatWillYouDo/web-dev.png"}
                            alt="Book img"
                            placeholder="blurred"
                        />
                        <BoxText>
                            {"<Will Understand The It Language Better>"}
                        </BoxText>
                    </CornerCutBoxInside>
                </CornerCutBox>
            </CornerCutBoxesContainer>
        </Container>
    );
};

export default WhatWillYouDo;
