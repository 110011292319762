import styled from "styled-components";

const WhatWillYouDoMobileStyles = {
    Container: styled.div`
        display: none;
        @media (max-width: 1075px) {
            width: 100%;
            display: flex;
            flex-flow: column;
            align-items: center;
            margin-top: 120px;
            margin-bottom: 50px;
        }
    `,

    ContentContainer: styled.div`
        width: 80%;
    `,

    TitleContainer: styled.div`
        position: relative;
        width: max-content;
        height: 15px;
        padding: 0px 20px;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid gray;
        border-left: 1px solid gray;
        border-right: 1px solid gray;
    `,

    TitleText: styled.div`
        position: relative;
        text-transform: uppercase;
        font-size: 2.5rem;
        bottom: 40px;
        @media (max-width: 700px) {
            bottom: 23px;
            font-size: 1.4rem;
        }
    `,

    BoxesContainer: styled.div`
        display: flex;
    `,

    LeftRowContainer: styled.div`
        width: 20%;
        display: flex;
        flex-flow: column;
    `,

    LeftBox: styled.div`
        border-left: 1px solid gray;
        border-bottom: 1px solid gray;
        width: 100%;
        height: ${(props) => (props.first ? "250px" : "400px")};
        @media (max-width: 700px) {
            height: ${(props) => (props.first ? "180px" : "300px")};
        }
    `,

    RightRowContainer: styled.div`
        width: 80%;
        display: flex;
        flex-flow: column;
    `,

    RightBox: styled.div`
        width: 100%;
        height: 400px;
        /* border: 2px solid black; */
        position: relative;
        top: 50px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        @media (max-width: 700px) {
            height: 300px;
            top: 40px;
        }
    `,

    CornerCutBox: styled.div`
        width: 350px;
        height: 260px;
        background-color: grey;
        clip-path: polygon(75% 0%, 100% 30%, 100% 100%, 0 100%, 0 0);
        /* clip-path: polygon(
            200px 0px,
            280px 75px,
            280px 260px,
            0px 260px,
            0px 0px
        ); */
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 700px) {
            width: 230px;
            height: 200px;
        }
    `,

    CornerCutBoxInside: styled.div`
        width: 348px;
        height: 258px;
        background-color: white;
        clip-path: polygon(75% 0%, 100% 30%, 100% 100%, 0 100%, 0 0);
        /* clip-path: polygon(
            200px 1px,
            279px 75px,
            279px 259px,
            1px 259px,
            1px 1px
        ); */
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        @media (max-width: 700px) {
            width: 228px;
            height: 198px;
        }
    `,

    BoxText: styled.div`
        width: 80%;
        font-size: 1.2rem;
        font-weight: bold;
        margin-top: 30px;
        display: flex;
        justify-content: center;
        @media (max-width: 700px) {
            font-size: 1rem;
        }
    `,
};

export default WhatWillYouDoMobileStyles;
