import styled from "styled-components";

const WhatWillYouDoStyles = {
    Container: styled.div`
        width: 100%;
        height: 1150px;
        display: flex;
        flex-flow: column;
        align-items: center;
        @media (max-width: 1075px) {
            display: none;
        }
    `,

    Title: styled.div`
        width: 500px;
        height: 35px;
        border-bottom: 1px solid gray;
        border-left: 1px solid gray;
        border-right: 1px solid gray;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-top: 110px;
    `,

    TitleText: styled.div`
        position: relative;
        top: -35px;
        font-size: 3rem;
    `,

    VerticalLineOne: styled.div`
        height: 100px;
        width: 0px;
        border-left: 1px solid grey;
    `,

    TwoCenterBoxes: styled.div`
        display: flex;
        justify-content: center;
        width: 100%;
    `,

    InsideBoxOne: styled.div`
        width: 30%;
        max-width: 500px;
        height: 120px;
        border-left: 1px solid gray;
        border-right: 1px solid gray;
        border-top: 1px solid gray;
    `,

    InsideBoxTwo: styled.div`
        width: 30%;
        max-width: 500px;
        height: 120px;
        border-right: 1px solid gray;
        border-top: 1px solid gray;
    `,

    CornerCutBoxesContainer: styled.div`
        /* width: 88%; */
        width: ${(props) => (props.width ? props.width : 80)}%;
        /* max-width: 1800px; */
        max-width: ${(props) => (props.maxWidth ? props.maxWidth : 1250)}px;
        display: flex;
        justify-content: space-between;
    `,

    CornerCutBox: styled.div`
        width: 280px;
        height: 260px;
        /* border: 1px solid grey; */
        background-color: grey;
        /* clip-path: polygon(85% 0%, 100% 40%, 100% 100%, 0 100%, 0 0); */
        /* clip-path: polygon(
            230px 0px,
            350px 90px,
            350px 350px,
            0px 350px,
            0px 0px
        ); */
        clip-path: polygon(
            200px 0px,
            280px 75px,
            280px 260px,
            0px 260px,
            0px 0px
        );
        display: flex;
        justify-content: center;
        align-items: center;
    `,

    CornerCutBoxInside: styled.div`
        width: 280px;
        height: 260px;
        /* border: 1px solid grey; */
        background-color: white;
        /* clip-path: polygon(85% 0%, 100% 40%, 100% 100%, 0 100%, 0 0); */
        /* clip-path: polygon(
            230px 1px,
            349px 90px,
            349px 349px,
            1px 349px,
            1px 1px
        ); */
        clip-path: polygon(
            200px 1px,
            279px 75px,
            279px 259px,
            1px 259px,
            1px 1px
        );

        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
    `,

    BoxText: styled.div`
        width: 80%;
        /* border: 2px solid black; */
        font-size: 1.2rem;
        font-weight: bold;
        margin-top: 30px;
        /* padding: 0px 30px; */
        display: flex;
        justify-content: center;
    `,
};

export default WhatWillYouDoStyles;
