import * as React from "react";
import Home from "../components/HomePage/Home/Home";
import WhatWillYouDo from "../components/HomePage/WhatWillYouDo/WhatWillYouDo";
import ThreePoints from "../components/HomePage/ThreePoints/ThreePoints";
import OakAdventure from "../components/HomePage/OakAdventure/OakAdventure";
import Layout from "../components/Layout/Layout";
import SubTitleMobileSection from "../components/HomePage/Mobile/SubTitleMobileSection";
import WhatWillYouDoMobile from "../components/HomePage/WhatWillYouDo/Mobile/WhatWillYouDoMobile";
import { Helmet } from "react-helmet";

const IndexPage = () => {
    return (
        <Layout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>OakForge</title>
                <meta
                    name="description"
                    content="Oakforge - your best starting place in IT world"
                />
            </Helmet>
            <Home />
            <SubTitleMobileSection />
            <WhatWillYouDo />
            <WhatWillYouDoMobile />
            <ThreePoints />
            <OakAdventure />
        </Layout>
    );
};

export default IndexPage;
