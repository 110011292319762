import styled from "styled-components";

const SocialMediaStyles = {
    SocialMediaContainer: styled.div`
        position: absolute;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        height: 100%;
        z-index: 2;

        @media (max-width: 900px) {
            display: none;
        }
    `,

    SocialMediaBoxes: styled.div`
        width: 35px;
        height: 160px;
        /* border: 1px solid gray; */
        margin-right: 4%;
        display: flex;
        flex-flow: column;
    `,

    SocialMediaBox: styled.div`
        width: 100%;
        height: 100%;
        border-bottom: 1px solid grey;
        border-right: 1px solid grey;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    `,
};

export default SocialMediaStyles;
