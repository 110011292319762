import styled from "styled-components";

const SubTitleMobileSectionStyles = {
    Container: styled.div`
        display: none;
        @media (max-width: 900px) {
            width: 90%;
            display: flex;
            flex-flow: column;
            justify-content: space-between;
            gap: 50px;
            align-items: center;
            margin-top: 20px;
            margin-bottom: 50px;
        }
    `,

    TextContainer: styled.div`
        width: 90%;
        margin-left: 10%;
        display: flex;
        justify-content: flex-start;
        font-size: 1.5rem;
        line-height: 35px;
    `,
};

export default SubTitleMobileSectionStyles;
