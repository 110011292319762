import React from "react";
import HomeStyles from "./Home.styles";
import { StaticImage } from "gatsby-plugin-image";
import SocialMedia from "../../SocialMedia/SocialMedia";

const {
    Container,
    PeopleHome,
    PeopleHomeImage,
    Circle,
    TextContainer,
    TextTitle,
    TextSubtitle,
    SquaresContainer,
    SquaresImage,
} = HomeStyles;

const Home = () => {
    return (
        <Container>
            <SquaresContainer>
                <SquaresImage>
                    <StaticImage
                        src="../../../images/home-squares.png"
                        alt="squares"
                        placeholder="blurred"
                    />
                </SquaresImage>
            </SquaresContainer>
            <PeopleHome>
                <PeopleHomeImage>
                    <StaticImage
                        src="../../../images/home-people.png"
                        alt="Home People"
                        placeholder="blurred"
                        imgStyle={{ zIndex: "1" }}
                        // quality={100}
                    />
                    <Circle />
                </PeopleHomeImage>
            </PeopleHome>
            <TextContainer>
                <TextTitle style={{ marginTop: "180px" }}>
                    Skyrocket your
                </TextTitle>
                <TextTitle>programming career</TextTitle>
                <TextSubtitle>
                    Talents Forge was created to introduce young programmers
                    into the IT world and test them against challenges that
                    professional face every day. It’s not just an ordinary
                    programming course, but a place where future developers work
                    on a real IT projects.
                </TextSubtitle>
            </TextContainer>

            <SocialMedia />
        </Container>
    );
};

export default Home;
